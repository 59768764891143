<template>
  <div>
    <PageHeader />
    <b-overlay :show="isFetching">
      <b-card class="mb-4">
        <ValidationObserver
          ref="botSettings"
          v-slot="{ handleSubmit }"
        >
          <form @submit.prevent="handleSubmit(onSubmit)">
            <b-form-group
              :label="$t('fields.status')"
              label-for="status"
            >
              <b-form-radio-group
                id="status"
                v-model="depositRewardsForm.status"
                name="status"
              >
                <b-form-radio value="enable">
                  {{$t('utils.status.enable')}}
                </b-form-radio>
                <b-form-radio value="disable">
                  {{$t('utils.status.disable')}}
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
            <b-form-group
              label="วันที่เริ่ม - สิ้นสุด"
              label-for="start_date"
            >
              <b-row>
                <b-col>
                  <b-input-group prepend="เริ่ม">
                    <b-form-input
                      id="start_date"
                      v-model="depositRewardsForm.start_date"
                      v-mask="'##-##-####'"
                      placeholder="DD-MM-YYYY (01/01/2021)"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col>
                  <b-input-group prepend="สิ้นสุด">

                    <b-form-input
                      id="end_date"
                      v-model="depositRewardsForm.end_date"
                      v-mask="'##-##-####'"
                      placeholder="DD-MM-YYYY (01/01/2021)"
                    ></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group
              label="ยอดฝากสะสมต่อวัน"
              label-for="amount"
            >
              <b-input-group append="บาท">
                <b-form-input
                  id="amount"
                  v-model="depositRewardsForm.amount"
                  type="number"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="ยอดเงินรางวัล"
              label-for="cashback"
            >
              <b-input-group :append="depositRewardsForm.cashback_type === 'AMOUNT'?'฿': '%'">
                <template #prepend>
                  <b-form-select
                    id="cashback"
                    v-model="depositRewardsForm.cashback_type"
                    :options="typeOptions"
                  >
                  </b-form-select>
                </template>
                <b-input
                  v-model="depositRewardsForm.cashback_value"
                  type="number"
                >
                </b-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="ยอดเงินรางวัลสูงสุด / ครั้ง"
              label-for="max_cashback"
            >
              <b-input-group append="บาท">
                <b-form-input
                  id="max_cashback"
                  v-model="depositRewardsForm.max_cashback"
                ></b-form-input>
              </b-input-group>
            </b-form-group>
            <b-form-group
              label="หัวข้อ"
              label-for="title"
            >
              <b-input
                id="title"
                v-model="depositRewardsForm.title"
              >
              </b-input>
            </b-form-group>
            <b-form-group :label="$t('fields.description')">
              <vue-editor v-model="depositRewardsForm.description"></vue-editor>
            </b-form-group>
            <b-form-group
              :label="$t('fields.image')"
              label-for="img"
            >
              <b-form-file
                id="img"
                v-model="file"
                accept="image/*"
              >
              </b-form-file>
              <b-form-text>
                <b-link
                  v-if="file"
                  class="text-danger"
                  @click="file = ''"
                >
                  {{ $t('buttons.remove')}}
                </b-link>
              </b-form-text>
            </b-form-group>
            <div class="text-right">
              <!-- <b-overlay
                :show="isUpdatingDepositRewardsSettings"
                rounded
                spinner-small
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                  block
                >
                  {{ $t('buttons.edit')}}
                </b-button>
              </b-overlay> -->
            </div>
          </form>
        </ValidationObserver>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Deposit promotions',
  },
  components: { VueEditor },
  data() {
    return {
      file: null,
      typeOptions: [
        { text: this.$t('fields.amount'), value: 'AMOUNT' },
        { text: this.$t('fields.percentage'), value: 'PERCENT' },
      ],
      depositRewardsForm: {
        start_date: '',
        end_date: '',
        cashback_type: 'PERCENT',
        cashback_value: 0,
        max_cashback: 0,
        amount: 0,
        status: 'enable',
        title: '',
        description: '',
        img: '',
      },
    }
  },
  computed: {
    ...mapState({}),
    ...mapGetters(['depositRewardsSettings']),
    isFetching() {
      return false
    },
  },
  watch: {
    depositRewardsSettings(val) {
      if (val) {
        this.setData()
      }
    },
  },
  created() {
  },
  methods: {
    ...mapActions([]),
    setData() {
      if (this.depositRewardsSettings) {
        const data = JSON.parse(JSON.stringify(this.depositRewardsSettings))
        this.depositRewardsForm = { ...this.depositRewardsForm, ...data }
      } else {
        this.depositRewardsForm = {
          start_date: '',
          end_date: '',
          cashback_type: 'PERCENT',
          cashback_value: 0,
          max_cashback: 0,
          amount: 0,
          status: 'enable',
          title: '',
          description: '',
          img: '',
        }
      }
    },
    onSubmit() {
      this.depositRewardsForm.img = this.file
      //   this.CashbackSettings(this.depositRewardsForm)
    },
  },
}
</script>
